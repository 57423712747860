@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html{
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}

body{
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: .9em;
    line-height: 2em;
}

.list-group-item{
  border: 0px;
}

.Accordion .Accordion.Item.active .Accordion.Header {
  background-color: transparent;
  /* Set the background color to transparent for the selected header */
  color: black;
  /* Set the text color for the selected header */
}